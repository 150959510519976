import Vue from "vue";
import Router from "vue-router";
import VueSocketIO from "vue-socket.io";
// import Login from "../views/account/login.vue";
// import ForgotPassword from "../views/account/forgot-password.vue";
// import Dashboard from "../views/dashboard.vue";
// import OrderList from "../views/orders/list.vue";
// import Order from "../views/orders/main.vue";
// import VendorList from "../views/restaurant/list.vue";
// import VendorAdd from "../views/restaurant/add.vue";
// import VendorDetails from "../views/restaurant/details.vue";
// import CustomerList from "../views/customer/list.vue";
// import CustomerDetails from "../views/customer/details.vue";
// import DriverList from "../views/driver/list.vue";
// import DriverAdd from "../views/driver/add.vue";
// import DriverDetails from "../views/driver/details.vue";
// import CountryList from "../views/location/list.vue";
// import StateList from "../views/location/state-list.vue";
// import CityList from "../views/location/city-list.vue";
// import DeliveryList from "../views/delivery_zone/list.vue";
// import DeliveryAdd from "../views/delivery_zone/add.vue";
// import DeliveryEdit from "../views/delivery_zone/edit.vue";

// driver signup
// import DriverSignup from "../views/driver/signup.vue";
// driver signup
// import DeliveryZoneDetails from "../views/delivery_zone/details.vue";
// import DeliveryAddArea from "../views/delivery_zone/add-area.vue";
// import DeliveryEditArea from "../views/delivery_zone/edit-area.vue";
// import CouponList from "../views/coupon/list.vue";
// import CouponAdd from "../views/coupon/add.vue";
// import CouponEdit from "../views/coupon/edit.vue";
// import BannerList from "../views/banner/list.vue";
// import BannerAdd from "../views/banner/add.vue";
// import BannerEdit from "../views/banner/edit.vue";
// import CategoryList from "../views/category/list.vue";
// import CategoryAdd from "../views/category/add.vue";
// import CategoryEdit from "../views/category/edit.vue";
// import FaqList from '../views/faq/list.vue';
// import FaqAdd from '../views/faq/add.vue';
// import FaqEdit from '../views/faq/edit.vue';
// import SystemList from '../views/system/list.vue';
// import CuisinesList from '../views/cuisines/list.vue';
// import CuisinesAdd from '../views/cuisines/add.vue';
// import CuisinesEdit from '../views/cuisines/edit.vue';
// import Configuration from '../views/settings/configurations.vue';
// import Themes from '../views/settings/themes.vue';
// import EagleView from '../views/eagle-view/list.vue';
// import ContentPage from '../views/content-page/list.vue';
// import Reports from '../views/reports/list.vue';
// import Terminology from '../views/settings/terminology.vue';
import Invoice from "../views/orders/invoice.vue";
// import VendorFinance from "../views/finance/vendor.vue";
// import VendorFinanceMonth from '../views/finance/vendor-finance-month.vue';
// import VendorFinanceList from '../views/finance/vendor-finance-list.vue';
// import RestaurantFinance from '../views/finance/restaurant/restaurant-finance.vue';
// import RestaurantFinanceTransaction from '../views/finance/restaurant/restaurant-transaction.vue';
// import DriverDashboard from '../views/driver/dashboard.vue';
// import DriverFinance from '../views/finance/driver.vue';
// import DriverFinanceDetail from '../views/finance/driver/driver-finance-detail.vue';
// import DriverFinanceMonth from '../views/finance/driver/driver-finance-month.vue';
// import DriverTransaction from '../views/finance/driver/driver-transaction.vue';
// import Profile from '../views/profile/subscription_info.vue';
// import UserAccess from '../views/settings/user-access/user-list.vue';
// import UserAccessAdd from '../views/settings/user-access/add-user.vue';
// import UserAccessEdit from '../views/settings/user-access/edit-user.vue';
// import UserAccessPermission from '../views/settings/user-access/user-permission.vue';

//import Catalog from '../views/restaurant/catalog.vue';
// import Catalog from '../views/restaurant/new-catalog.vue';

// import Kanbanboard from '../views/restaurant/kanbanboard.vue';
// import WaitingList from '../views/restaurant/waiting-list.vue';

// import ChangePassword from '../views/profile/change-password.vue';
// import DiscountList from '../views/discount/discount-list.vue';
// import DiscountAdd from '../views/discount/add.vue';
// import DiscountEdit from '../views/discount/edit.vue';
// import BusinessProfile from '../views/profile/business-profile.vue';
// import SubVendorProfile from '../views/restaurant/profile.vue';
// import TimeSlot from '../views/timeslot/timeslots.vue';
// import SellingTimeSlot from '../views/timeslot/selling-timeslot.vue';
// import Reviews from '../views/reviews/list.vue';
// import Category from '../views/restaurant/category.vue';
// import SalesReport from '../views/reports/sales_report.vue';
// import WalletReport from '../views/reports/wallet_report.vue';
// import ItemReport from '../views/reports/item_report.vue';
// import CustomerReport from '../views/reports/customer_report.vue';
// import TotalSaleEarning from '../views/reports/total_sales_earning.vue';
// import TaxReport from '../views/reports/tax_report.vue';
// import DriverReport from '../views/reports/driver_report.vue';
// import InactiveCustomerReport from '../views/reports/inactive_customer_report.vue';
// import CODOnlineOrderReport from '../views/reports/cod_online_order_report.vue';
// import AvgRatingReport from '../views/reports/avg_rating_report.vue';
// import PromoCodeReport from '../views/reports/promo_code_report.vue';
// import DriverReportDetailsReport from '../views/reports/driver_report_details.vue';
// import AppLayoutSetting from '../views/settings/app-layout-setting.vue';
// import WebLayoutSetting from '../views/settings/web-layout-setting.vue';
// import AddCustomization from '../views/restaurant/add-customization.vue';
// import AddSubCustomization from '../views/restaurant/add-sub-customization.vue';
// import AddContentPage from '../views/content-page/add.vue';
// import EditContentPage from '../views/content-page/edit.vue';
// import ViewContentPage from '../views/content-page/view-page.vue';
import config from "../config";
import { orderService } from "../services";
// import OperationTime from '../views/restaurant/operation-time.vue';
// import VendorCategory from '../views/restaurant/vendor-category.vue';
// import VendorDiscount from '../views/restaurant/vendor-discount.vue';
// import VendorProfile from '../views/restaurant/vendor-profile.vue';
// import VendorRating from '../views/restaurant/vendor-rating.vue';
// import Signup from "../views/account/signup.vue";
import QuickSignup from "../views/account/quick-signup.vue";
// import HelpList from '../views/help/list.vue';
import { menuItems } from "../components/menu.js";
// import WhatsNew from '../views/whatsnew/whats-new.vue';
import { error_message } from "../Helper/helper.js";
// import SubVendorSignup from "../views/account/sub-vendor-signup.vue";
// import ClientDashboard from "../views/client/dashboard.vue";
// import ClientList from '../views/client/list.vue';
// import ClientAdd from '../views/client/add.vue';
// import ClientEdit from '../views/client/edit.vue';
// import CurrencyList from '../views/currency/list.vue';
// import ChangeLog from '../views/change-log.vue';
// import VendorRatingReviewReport from '../views/reports/vendor_rating_review_report.vue';
// import DriverRatingReviewReport from '../views/reports/driver_rating_review_report.vue';
// import DriverSettlementReport from '../views/reports/driver_settlement_report.vue';
// import CallCenterDashboard from '../views/call-center/dashboard.vue';
// import Analytics from '../views/analytics/analytics.vue';
// import RestaurantQRFeedbackReport from "../views/reports/feedback_report.vue";
// import MailList from "../views/mail/list.vue";
// import MailAdd from "../views/mail/add.vue";
// import MailEdit from "../views/mail/edit.vue";
// import MailView from '../views/mail/view-page.vue';
// import BusinessTypeList from '../views/business_type/list.vue';
// import BusinessTypeEdit from '../views/business_type/edit.vue';

// sales channel
// import Whatsapp from "../views/sales_channel/Whatsapp.vue";
// import GoogleMyBusiness from "../views/sales_channel/Google-my-business.vue"
// import Facebook from "../views/sales_channel/Facebook.vue"
// import OrderWidget from "../views/sales_channel/OrderWidget.vue";
// import MobileApp from "../views/sales_channel/Apps.vue"
// import Qr from "../views/sales_channel/Qr.vue"
// import OrderWebsite from "../views/sales_channel/OrderWebsite.vue"
// import CustomerApp from "../views/sales_channel/Customer-app.vue";
// import VendorApp from "../views/sales_channel/Vendor-app.vue";
// import DriverApp from "../views/sales_channel/Driver-app.vue";
// sales channel
// vendor tag
// import VendorTag from '../views/restaurant/vendor-tag.vue';

// brand
// import Brand from '../views/brand/brand.vue';
// import BrandAdd from '../views/brand/brand-add.vue';
// import BrandEdit from '../views/brand/brand-edit.vue';
// Chat
// import ChatListig from '../views/chat/chat.vue';
// import PermissionPage from '../views/permission-page.vue';
// import Billing from '../components/mybilling.vue';

// Saas Client
// import SaasClientList from '../views/saas_client/list';

// import TagList from '../views/tag/list.vue';
import RestaurantTable from "./pos/restaurant-table.vue";
// import TableView from './restaurant/table-view.vue';

// import SendNotification from '../views/send_notification/notification.vue'
// import NotificationHistory from '../views/send_notification/history.vue'

// import QuickHelp from '../views/help/quickhelp.vue';

Vue.use(Router);

const urls = menuItems.reduce(
    (m, i) => (i.subItems ? m.concat(i.subItems) : m.concat(i)),
    []
);

const base = config.base_folder;

const vendorRoutes = [
    {
        path: base + "login",
        name: "login",
        component: () => import("../views/account/login.vue"),
    },
    {
        path: base + "admin/login",
        name: "admin-login",
        component: () => import("../views/account/admin/login.vue"),
    },
    {
        path: base + "signup",
        name: "signup",
        //component: () => import("../views/account/signup.vue"),
        // component: () => import("../views/account/quick-signup.vue"),
        component: () => import("../views/account/new-quick-signup.vue"),
    },
    {
        path: base + "quick-signup",
        name: "quick-signup",
        //component: () => import("../views/account/quick-signup.vue"),
        component: () => import("../views/account/signup.vue"),
    },
    {
        path: base + "account-setup",
        name: "account-setup",
        component: () => import("../views/account/progress.vue"),
    },
    {
        path: base + "reseller",
        name: "reseller",
        component: () => import("../views/reseller/list.vue"),
        access: true,
    },
    {
        path: base + "dashboard",
        name: "dashboard",
        component: () => import("../views/dashboard.vue"),
    },
    {
        path: base + "orders/list",
        name: "orders-list",
        component: () => import("../views/orders/main.vue"),
    },
    {
        path: base + "subscriptions/list",
        name: "subscriptions-list",
        component: () => import("../views/subscriptions/main.vue"),
    },
    {
        path: base + "subscriptions/details/:id",
        name: "subscriptions.details",
        component: () => import("../views/subscriptions/details.vue"),
    },
    {
        path: base + "call-center/dashboard",
        name: "call-center-dashboard",
        component: () => import("../views/call-center/dashboard.vue"),
    },
    {
        path: base + "vendors",
        name: "vendor-list",
        component: () => import("../views/restaurant/list.vue"),
    },
    {
        path: base + "catalog",
        name: "catalog-list",
        component: () => import("../views/restaurant/catalog/catalog.vue"),
    },
    {
        path: base + "vendors/add",
        name: "vendor-add",
        component: () => import("../views/restaurant/add.vue"),
    },
    {
        path: base + "vendors/details/:restaurant_id",
        name: "vendor-detail",
        component: () => import("../views/restaurant/details.vue"),
    },
    {
        path: base + "vendors/catalog/:restaurant_id",
        name: "vendor-catalog",
        component: () => import("../views/restaurant/new-catalog.vue"),
    },
    {
        path: base + "vendors/add-customization/:menu_item_id",
        name: "vendor-add-customization",
        component: () => import("../views/restaurant/add-customization.vue"),
    },
    {
        path: base + "vendors/add-sub-customization/:menu_item_selection_id",
        name: "vendor-add-sub-customization",
        component: () =>
            import("../views/restaurant/add-sub-customization.vue"),
    },
    {
        path: base + "customer",
        name: "customer-list",
        //component: () => import("../views/customer/list.vue"),
        component: () => import("../views/customer/base-customer-view.vue"),
    },
    {
        path: base + "customer/segmentation/all-customer",
        name: "customer.segmentation.all-customer",
        component: () => import("../views/customer/all-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/day-wise-customer",
        name: "customer.segmentation.day-wise-customer",
        component: () => import("../views/customer/day-wise-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/no-order-customer",
        name: "customer.segmentation.no-order-customer",
        component: () =>
            import("../views/customer/no-order-days-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/with-wallet-balance-customer",
        name: "customer.segmentation.with-wallet-balance-customer",
        component: () =>
            import("../views/customer/with-wallet-balance-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/with-cancel-order-customer",
        name: "customer.segmentation.with-cancel-order-customer",
        component: () =>
            import("../views/customer/with-cancel-order-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/with-maximum-order-customer",
        name: "customer.segmentation.with-maximum-order-customer",
        component: () =>
            import("../views/customer/with-maximum-order-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/with-os-wise-customer",
        name: "customer.segmentation.with-os-wise-customer",
        component: () =>
            import("../views/customer/operating-system-wise-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/with-average-order-customer",
        name: "customer.segmentation.with-average-order-customer",
        component: () =>
            import("../views/customer/with-average-order-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/with-promo-code-usage-customer",
        name: "customer.segmentation.with-promo-code-usage-customer",
        component: () =>
            import("../views/customer/with-promo-code-usage-customer/list.vue"),
    },
    {
        path: base + "customer/segmentation/posted-restaurant-negative-reviews",
        name: "customer.segmentation.posted-restaurant-negative-reviews",
        component: () =>
            import(
                "../views/customer/posted-restaurant-negative-reviews/list.vue"
            ),
    },
    {
        path: base + "customer/segmentation/posted-driver-negative-reviews",
        name: "customer.segmentation.posted-driver-negative-reviews",
        component: () =>
            import("../views/customer/posted-driver-negative-reviews/list.vue"),
    },
    {
        path: base + "customer/segmentation/pro-customers",
        name: "customer.segmentation.pro-customers",
        component: () => import("../views/customer/pro-customers/list.vue"),
    },
    {
        path: base + "customer/details/:user_id",
        name: "customer-detail",
        component: () => import("../views/customer/details.vue"),
    },
    {
        path: base + "drivers/:vendor_id/signup",
        name: "driver-signup",
        component: () => import("../views/driver/signup.vue"),
    },
    {
        path: base + "driver/dashboard",
        name: "driver-dashboard",
        component: () => import("../views/driver/dashboard.vue"),
    },
    {
        path: base + "drivers",
        name: "driver-list",
        component: () => import("../views/driver/list.vue"),
    },
    {
        path: base + "drivers/add",
        name: "driver-add",
        component: () => import("../views/driver/add.vue"),
    },
    {
        path: base + "drivers/details/:driver_id",
        name: "driver-details",
        component: () => import("../views/driver/details.vue"),
    },
    {
        path: base + "location",
        name: "country-list",
        component: () => import("../views/location/list.vue"),
    },
    {
        path: base + "location/state-list/:country_id",
        name: "state-list",
        component: () => import("../views/location/state-list.vue"),
    },
    {
        path: base + "location/city-list/:state_id",
        name: "city-list",
        component: () => import("../views/location/city-list.vue"),
    },
    {
        path: base + "delivery_zone",
        name: "list",
        component: () => import("../views/delivery_zone/list.vue"),
    },
    {
        path: base + "delivery_zone/add",
        name: "delivery-zone-add",
        component: () => import("../views/delivery_zone/add.vue"),
    },
    {
        path: base + "delivery_zone/edit/:user_id",
        name: "delivery-zone-edit",
        component: () => import("../views/delivery_zone/edit.vue"),
    },
    {
        path: base + "delivery_zone/details/:user_id",
        name: "delivery-zone-details",
        component: () => import("../views/delivery_zone/details.vue"),
    },
    {
        path: base + "delivery_zone/add-area/:frenchiese_id",
        name: "add-area",
        component: () => import("../views/delivery_zone/add-area.vue"),
    },
    {
        path: base + "delivery_zone/edit-area/:area_id",
        name: "edit-area",
        component: () => import("../views/delivery_zone/edit-area.vue"),
    },
    {
        path: base + "coupon",
        name: "coupon-list",
        component: () => import("../views/coupon/list.vue"),
    },
    {
        path: base + "coupon/add",
        name: "coupon-add",
        component: () => import("../views/coupon/add.vue"),
    },
    {
        path: base + "coupon/edit/:id",
        name: "coupon-edit",
        component: () => import("../views/coupon/edit.vue"),
    },
    {
        path: base + "banner",
        name: "banner-list",
        component: () => import("../views/banner/list.vue"),
    },
    {
        path: base + "banner/add",
        name: "banner-add",
        component: () => import("../views/banner/add.vue"),
    },
    {
        path: base + "banner/edit/:id",
        name: "banner-edit",
        component: () => import("../views/banner/edit.vue"),
    },
    {
        path: base + "finance/driver",
        name: "driver",
        component: () => import("../views/finance/driver.vue"),
    },
    {
        path: base + "finance/driver/month/:month",
        name: "driver-finance-month",
        component: () =>
            import("../views/finance/driver/driver-finance-month.vue"),
    },
    {
        path: base + "finance/driver/:driverid/transactions",
        name: "driver-finance-transactions",
        component: () =>
            import("../views/finance/driver/driver-transaction.vue"),
    },
    {
        path: base + "finance/vendor",
        name: "vendor",
        component: () => import("../views/finance/vendor.vue"),
    },
    {
        path: base + "finance/vendor/month/:month",
        name: "vendor-finance-month",
        component: () => import("../views/finance/vendor-finance-month.vue"),
    },
    {
        path: base + "finance/vendor-finance-list",
        name: "vendor-finance-list",
        component: () => import("../views/finance/vendor-finance-list.vue"),
    },
    {
        path: base + "finance/vendor/month/:month/:id/dashboard",
        name: "restaurant-finance-list",
        component: () =>
            import("../views/finance/restaurant/restaurant-finance.vue"),
    },
    {
        path: base + "finance/vendor/:id/transactions",
        name: "restaurant-transaction-list",
        component: () =>
            import("../views/finance/restaurant/restaurant-transaction.vue"),
    },
    {
        path: base + "faq",
        name: "faq-list",
        component: () => import("../views/faq/list.vue"),
    },
    {
        path: base + "faq/add",
        name: "faq-add",
        component: () => import("../views/faq/add.vue"),
    },
    {
        path: base + "faq/edit/:id",
        name: "faq-edit",
        component: () => import("../views/faq/edit.vue"),
    },
    // order configurations
    {
        path: base + "settings/delivery-zone-configurations",
        name: "delivery-zone-configurations",
        component: () =>
            import("../views/settings/delivery-zone-configurations.vue"),
    },
    // Driver configurations
    {
        path: base + "settings/driver-configurations",
        name: "driver-configurations",
        component: () => import("../views/settings/driver-configurations.vue"),
    },
    // General Configurations in Dz
    {
        path: base + "settings/general-configurations",
        name: "general-configurations",
        component: () => import("../views/settings/general-configurations.vue"),
    },
    {
        path: base + "reports",
        name: "reports-list",
        component: () => import("../views/reports/list.vue"),
    },
    {
        path: base + "reports/sales_report",
        name: "reports.sale.report",
        component: () => import("../views/reports/sales_report.vue"),
    },
    {
        path: base + "reports/customer_report",
        name: "reports.customer.report",
        component: () => import("../views/reports/customer_report.vue"),
    },
    {
        path: base + "reports/total_sales_earning",
        name: "reports.total.earning",
        component: () => import("../views/reports/total_sales_earning.vue"),
    },
    {
        path: base + "reports/tax_report",
        name: "reports.tax.report",
        component: () => import("../views/reports/tax_report.vue"),
    },
    {
        path: base + "reports/driver_report",
        name: "reports.driver.report",
        component: () => import("../views/reports/driver_report.vue"),
    },
    {
        path: base + "reports/inactive_customer_report",
        name: "reports.inactive.customer.report",
        component: () =>
            import("../views/reports/inactive_customer_report.vue"),
    },
    {
        path: base + "reports/cod_online_order_report",
        name: "reports.cod.online.order.report",
        component: () => import("../views/reports/cod_online_order_report.vue"),
    },
    {
        path: base + "reports/avg_rating_report",
        name: "reports.avg.rating.report",
        component: () => import("../views/reports/avg_rating_report.vue"),
    },
    {
        path: base + "reports/promo_code_report",
        name: "reports.promo.code.report",
        component: () => import("../views/reports/promo_code_report.vue"),
    },
    {
        path: base + "reports/driver_report_details",
        name: "driver.reports.details",
        component: () => import("../views/reports/driver_report_details.vue"),
    },
    {
        path: base + "reports/wallet_report",
        name: "report.wallet",
        component: () => import("../views/reports/wallet_report.vue"),
    },
    {
        path: base + "settings/terminology",
        name: "terminology-list",
        component: () => import("../views/settings/terminology.vue"),
    },
    {
        path: base + "profile/subscription_info",
        name: "subscription-info",
        component: () => import("../views/profile/subscription_info.vue"),
        access: true,
    },
    {
        path: base + "profile/change-password",
        name: "change-password",
        component: () => import("../views/profile/change-password.vue"),
        access: true,
    },
    {
        path: base + "profile/business-profile",
        name: "business-profile",
        component: () => import("../views/profile/business-profile.vue"),
        access: true,
    },
    {
        path: base + "help/list",
        name: "help",
        component: () => import("../views/help/list.vue"),
        access: true,
    },
    {
        path: base + "whatsnew",
        name: "whats-new",
        component: () => import("../views/whatsnew/whats-new.vue"),
        access: true,
    },
    {
        path: base + "client/list",
        name: "client-list",
        component: () => import("../views/client/list.vue"),
        access: true,
    },
    {
        path: base + "currency",
        name: "currency-list",
        component: () => import("../views/currency/list.vue"),
        access: true,
    },
    {
        path: base + "client-reports",
        name: "client-report",
        component: () => import("../views/client_report/report.vue"),
        access: true,
    },
    {
        path: base + "dashboard-release-notes",
        name: "dashboard-release-notes-list",
        component: () =>
            import("../views/release-notes/dashboard-release-notes/list.vue"),
        access: true,
    },
    {
        path: base + "add-dashboard-release-notes",
        name: "add-dashboard-release-notes",
        component: () =>
            import("../views/release-notes/dashboard-release-notes/add.vue"),
        access: true,
    },
    {
        path: base + "edit-dashboard-release-notes/:id",
        name: "edit-dashboard-release-notes",
        component: () =>
            import("../views/release-notes/dashboard-release-notes/edit.vue"),
        access: true,
    },
    // {
    //     path: base + "view-release-notes/:id",
    //     name: "view-release-notes",
    //     component: () => import("../views/release-notes/dashboard-release-notes/view-page.vue"),
    //     access: true,
    // },
    {
        path: base + "maintenance/system",
        name: "maintenance-system",
        component: () => import("../views/maintenance/system.vue"),
        access: true,
    },
    {
        path: base + "changelog",
        name: "change-log",
        component: () => import("../views/change-log.vue"),
        access: true,
    },
    {
        path: base + "reports/vendor_rating_review_report",
        name: "reports.vendor.rating.review.report",
        component: () =>
            import("../views/reports/vendor_rating_review_report.vue"),
    },
    {
        path: base + "reports/driver_rating_review_report",
        name: "reports.driver.rating.review.report",
        component: () =>
            import("../views/reports/driver_rating_review_report.vue"),
    },
    {
        path: base + "reports/order-status-report",
        name: "reports.order-status.report",
        component: () =>
            import("../views/reports/order-status/order-status-report.vue"),
    },

    {
        path: base + "reports/subscription-report",
        name: "reports.subscription.report",
        component: () => import("../views/reports/subscription_report.vue"),
    },
    {
        path: base + "reports/subscription-order-report",
        name: "reports.subscription.order.report",
        component: () =>
            import("../views/reports/subscription_order_report.vue"),
    },

    {
        path: base + "reports/driver_settlement_report",
        name: "reports.driver.settlement",
        component: () =>
            import("../views/reports/driver_settlement_report.vue"),
    },
    {
        path: base + "analytics",
        name: "analytics",
        component: () => import("../views/analytics/analytics.vue"),
        access: true,
    },
    {
        path: base + "business-type",
        name: "business-type-list",
        component: () => import("../views/business_type/list.vue"),
        access: true,
    },
    {
        path: base + "business-type/edit/:id",
        name: "business-type-edit",
        component: () => import("../views/business_type/edit.vue"),
        access: true,
    },
    {
        path: base + "wallet-dashboard",
        name: "wallet-dashboard",
        //component: () => import("../views/finance/wallet/wallet-dashboard.vue"),
        component: () => import("../views/finance/wallet/base-view.vue"),
        // access: true,
    },
    {
        path: base + "wallet-dashboard/month/:month",
        name: "wallet-dashboard-finance-month",
        component: () => import("../views/finance/wallet/finance-month.vue"),
    },
    {
        path: base + "finance/driver/month/:month/:driverid/dashboard",
        name: "driver-finance",
        component: () =>
            import("../views/finance/driver/driver-finance-detail.vue"),
    },
    {
        path: base + "category",
        name: "category-list",
        component: () => import("../views/category/list.vue"),
    },
    {
        path: base + "category/add",
        name: "category-add",
        component: () => import("../views/category/add.vue"),
    },
    {
        path: base + "category/edit/:category_id",
        name: "category-edit",
        component: () => import("../views/category/edit.vue"),
    },
    {
        path: base + "system/list",
        name: "system-list",
        component: () => import("../views/system/list.vue"),
    },
    {
        path: base + "release-notes/list",
        name: "release-notes-list",
        component: () => import("../views/release-notes/client/list.vue"),
        access: true,
    },
    {
        path: base + "cuisines",
        name: "cuisines-list",
        component: () => import("../views/cuisines/list.vue"),
    },
    {
        path: base + "cuisines/add",
        name: "cuisines-add",
        component: () => import("../views/cuisines/add.vue"),
    },
    {
        path: base + "cuisines/edit/:cuisine_id",
        name: "cuisines-edit",
        component: () => import("../views/cuisines/edit.vue"),
    },
    {
        path: base + "settings/configurations",
        name: "configurations",
        component: () => import("../views/settings/configurations.vue"),
    },
    {
        path: base + "settings/integrations",
        name: "integrations",
        component: () => import("../views/settings/integration.vue"),
        access: true,
    },
    {
        path: base + "settings/app-layout-setting",
        name: "app-layout-setting",
        component: () => import("../views/settings/app-layout-setting.vue"),
    },
    {
        path: base + "settings/web-layout-setting",
        name: "web-layout-setting",
        component: () => import("../views/settings/web-layout-setting.vue"),
        access: true,
    },
    {
        path: base + "settings/themes",
        name: "themes",
        component: () => import("../views/settings/themes.vue"),
    },
    {
        path: base + "eagle-view/list",
        name: "eagle-list",
        component: () => import("../views/eagle-view/list.vue"),
    },
    {
        path: base + "content-page",
        name: "content-list",
        component: () => import("../views/content-page/list.vue"),
    },
    {
        path: base + "settings/user-access",
        name: "user-access",
        component: () => import("../views/settings/user-access/user-list.vue"),
        access: true,
    },
    {
        path: base + "settings/user-access/add",
        name: "user-access-add",
        component: () => import("../views/settings/user-access/add-user.vue"),
    },
    {
        path: base + "settings/user-access/:id/edit",
        name: "user-access-edit",
        component: () => import("../views/settings/user-access/edit-user.vue"),
    },
    {
        path: base + "settings/user-access/:id/permissions",
        name: "user-access.permissions",
        component: () =>
            import("../views/settings/user-access/user-permission.vue"),
    },
    {
        path: base + "content-page/add",
        name: "content-page-add",
        component: () => import("../views/content-page/add.vue"),
    },
    {
        path: base + "content-page/edit/:id",
        name: "content-page-edit",
        component: () => import("../views/content-page/edit.vue"),
    },
    {
        path: base + "content-page/view/:id",
        name: "content-page-view",
        component: () => import("../views/content-page/view-page.vue"),
    },
    {
        path: base + "client",
        name: "client-dashboard",
        component: () => import("../views/client/dashboard.vue"),
        access: true,
    },
    {
        path: base + "client/add",
        name: "client-add",
        component: () => import("../views/client/add.vue"),
        access: true,
    },
    {
        path: base + "reseller/add",
        name: "reseller-add",
        component: () => import("../views/reseller/business-profile.vue"),
        access: true,
    },
    {
        path: base + "reseller/edit/:vendor_id",
        name: "reseller-edit",
        component: () => import("../views/reseller/edit-business-profile.vue"),
        access: true,
    },
    {
        path: base + "client/edit/:vendor_id",
        name: "client-edit",
        component: () => import("../views/client/edit.vue"),
        access: true,
    },
    {
        path: base + "currency",
        name: "currency-list",
        component: () => import("../views/currency/list.vue"),
        access: true,
    },
    {
        path: base + "reports/feedback_report",
        name: "reports.feedback.report",
        component: () => import("../views/reports/feedback_report.vue"),
    },
    {
        path: base + "mails",
        name: "mail-list",
        component: () => import("../views/mail/list.vue"),
    },
    {
        path: base + "mails/add",
        name: "add-mail",
        component: () => import("../views/mail/add.vue"),
    },
    {
        path: base + "mails/edit/:id",
        name: "edit-mail",
        component: () => import("../views/mail/edit.vue"),
    },
    {
        path: base + "mails/view-page/:id",
        name: "view-mail",
        component: () => import("../views/mail/view-page.vue"),
    },

    // sales channel
    // {
    //   path: base+'sales-channels/whatsapp',
    //   name:"sales-channels-whatsapp",
    //   component: Whatsapp,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/google-my-business',
    //   name:"sales-channels-google-my-business",
    //   component: GoogleMyBusiness,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/facebook',
    //   name:"sales-channels-facebook",
    //   component: Facebook,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/orderwidget',
    //   name:"sales-channels-orderwidget",
    //   component: OrderWidget,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/apps',
    //   name:"sales-channels-apps",
    //   component: MobileApp,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/qrcode',
    //   name:"sales-channels-qrcode",
    //   component: Qr,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/orderwebsite',
    //   name:"sales-channels-orderwebsite",
    //   component: OrderWebsite,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/customer-app',
    //   name:"sales-channels-customer-app",
    //   component: CustomerApp,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/vendor-app',
    //   name:"sales-channels-vendor-app",
    //   component: VendorApp,
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/driver-app',
    //   name:"sales-channels-driver-app",
    //   component: DriverApp,
    //   access:true,
    // },
    {
        path: base + "sales-channels",
        name: "sales-channels",
        component: () => import("../views/sales_channel/list.vue"),
        access: true,
    },
    {
        path: base + "sales-channels/whatsapp",
        name: "sales-channels-whatsapp",
        component: () => import("../views/sales_channel/Whatsapp.vue"),
        access: true,
    },
    {
        path: base + "sales-channels/google-my-business",
        name: "sales-channels-google-my-business",
        component: () =>
            import("../views/sales_channel/Google-my-business.vue"),
        access: true,
    },
    {
        path: base + "sales-channels/facebook",
        name: "sales-channels-facebook",
        component: () => import("../views/sales_channel/Facebook.vue"),
        access: true,
    },
    {
        path: base + "sales-channels/orderwidget",
        name: "sales-channels-orderwidget",
        component: () => import("../views/sales_channel/OrderWidget.vue"),
        access: true,
    },
    {
        path: base + "sales-channels/apps",
        name: "sales-channels-apps",
        component: () => import("../views/sales_channel/App.vue"),
        access: true,
    },
    {
        path: base + "sales-channels/qrcode",
        name: "sales-channels-qrcode",
        component: () => import("../views/sales_channel/Qr.vue"),
        access: true,
    },
    // {
    //   path: base+'sales-channels/orderwebsite',
    //   name:"sales-channels-orderwebsite",
    //   component: () => import('../views/sales_channel/OrderWebsite.vue'),
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/customer-app',
    //   name:"sales-channels-customer-app",
    //   component: () => import('../views/sales_channel/Customer-app.vue'),
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/vendor-app',
    //   name:"sales-channels-vendor-app",
    //   component: () => import('../views/sales_channel/Vendor-app.vue'),
    //   access:true,
    // },
    // {
    //   path: base+'sales-channels/driver-app',
    //   name:"sales-channels-driver-app",
    //   component: () => import('../views/sales_channel/Driver-app.vue'),
    //   access:true,
    // },
    // sales channel

    // brand
    {
        path: base + "brand",
        name: "brand",
        component: () => import("../views/brand/brand.vue"),
        access: true,
    },
    {
        path: base + "brand/add",
        name: "add-brand",
        component: () => import("../views/brand/brand-add.vue"),
        access: true,
    },
    {
        path: base + "brand/edit/:vendor_id",
        name: "edit-brand",
        component: () => import("../views/brand/brand-edit.vue"),
        access: true,
    },
    {
        path: base + "chat",
        name: "chat-list",
        component: () => import("../views/chat/chat.vue"),
    },
    {
        path: base + "review/list",
        name: "review-list",
        component: () => import("../views/review/list.vue"),
    },
    // {
    //   path : base+'review/driver',
    //   name : 'driver-review',
    //   component: () => import('../views/review/driver_review.vue')
    // },
    {
        path: base + "permission",
        name: "permission-page",
        component: () => import("../views/permission-page.vue"),
    },
    {
        path: base + "billing",
        name: "billing",
        component: () => import("../components/mybilling.vue"),
        access: true,
    },
    {
        path: base + "tag",
        name: "tag",
        component: () => import("../views/tag/list.vue"),
    },

    // Saas Client
    {
        path: base + "saas-client",
        name: "saas-client",
        component: () => import("../views/saas_client/list"),
        access: true,
    },
    {
        path: base + "restaurant/table-view",
        name: "Table View",
        component: () => import("./restaurant/table-view.vue"),
    },
    {
        path: base + "send_notification/send-notification",
        name: "send-notification",
        component: () => import("../views/send_notification/notification.vue"),
    },
    {
        path: base + "send_notification",
        name: "notification-history",
        component: () => import("../views/send_notification/history.vue"),
    },
    {
        path: base + "add-ons",
        name: "add-ons",
        component: () => import("../views/services.vue"),
        access: true,
    },
    {
        path: base + "quickhelp",
        name: "quick-help",
        component: () => import("../views/help/quickhelp.vue"),
        access: true,
    },
];

const restaurantRoutes = [
    {
        path: "*",
        redirect: base + "login",
    },
    {
        path: base + "vendor/login",
        name: "vendor.login",
        component: () => import("../views/account/login.vue"),
    },
    {
        path: base + "vendor/:vendor_id/signup",
        name: "vendor.signup",
        component: () => import("../views/account/sub-vendor-signup.vue"),
    },
    {
        path: base + "vendor/dashboard",
        name: "vendor.dashboard",
        component: () => import("../views/dashboard.vue"),
    },
    {
        path: base + "vendor/orders/list",
        name: "vendor.order.list",
        component: () => import("../views/orders/main.vue"),
    },
    {
        path: base + "vendor/subscription/list",
        name: "vendor.subscription.list",
        component: () => import("../views/vendor_subscription/main.vue"),
    },
    {
        path: base + "vendor/vendors/catalog",
        name: "vendor.vendor.catalog",
        component: () => import("../views/restaurant/new-catalog.vue"),
    },
    {
        path: base + "vendor/vendors/add-customization/:menu_item_id",
        name: "vendor.vendor.add.customization",
        component: () => import("../views/restaurant/add-customization.vue"),
    },
    {
        path:
            base +
            "vendor/vendors/add-sub-customization/:menu_item_selection_id",
        name: "vendor.vendor.add.sub.customization",
        component: () =>
            import("../views/restaurant/add-sub-customization.vue"),
    },
    {
        path: base + "vendor/coupon/list",
        name: "vendor.coupon.list",
        component: () => import("../views/coupon/list.vue"),
    },
    {
        path: base + "vendor/vendor-discount",
        name: "vendor.discount",
        component: () => import("../views/restaurant/vendor-discount.vue"),
    },
    {
        path: base + "vendor/finance-list",
        name: "vendor.finance.list",
        component: () =>
            import("../views/finance/restaurant/restaurant-finance.vue"),
    },
    {
        path: base + "vendor/reports",
        name: "vendor.reports.list",
        component: () => import("../views/reports/list.vue"),
    },
    {
        path: base + "vendor/reports/sales_report",
        name: "vendor.reports.sale.report",
        component: () => import("../views/reports/sales_report.vue"),
    },
    {
        path: base + "vendor/reports/item_report",
        name: "vendor.reports.item.report",
        component: () => import("../views/reports/item_report.vue"),
    },
    {
        path: base + "vendor/profile",
        name: "vendor.profile",
        component: () => import("../views/restaurant/vendor-profile.vue"),
    },
    {
        path: base + "vendor/operation-time",
        name: "vendor.timeslot",
        component: () => import("../views/restaurant/operation-time.vue"),
    },
    {
        path: base + "vendor/selling-timeslot",
        name: "vendor.selling.timeslot",
        component: () => import("../views/timeslot/selling-timeslot.vue"),
    },
    {
        path: base + "vendor/vendor-rating",
        name: "vendor.review",
        component: () => import("../views/restaurant/vendor-rating.vue"),
    },
    {
        path: base + "vendor/vendor-category",
        name: "vendor.restaurant.category",
        component: () => import("../views/restaurant/vendor-category.vue"),
    },
    {
        path: base + "vendor/finance/month",
        name: "vendor.restaurant.finance.list",
        component: () =>
            import("../views/finance/restaurant/restaurant-finance.vue"),
    },
    {
        path: base + "vendor/finance",
        name: "vendor.finance",
        component: () => import("../views/finance/vendor.vue"),
    },
    {
        path: base + "vendor/forgot-password",
        name: "forgot-password",
        component: () => import("../views/account/forgot-password.vue"),
    },
    {
        path: base + "vendor/vendor-tag",
        name: "vendor-tag",
        component: () => import("../views/restaurant/vendor-tag.vue"),
    },
    // {
    //   path:  base+'vendor/waiting',
    //   name: 'waiting-list',
    //   component:WaitingList
    // },
    // {
    //   path:  base+'vendor/pos',
    //   name: 'table-view',
    //   component:TableView
    // },
    // {
    //   path:  base+'vendor/restaurant-table',
    //   name: 'Table view',
    //   component:RestaurantTable
    // },
];

const notificationRoutes = [
    {
        path: base + "send_notification/send-notification",
        name: "send-notification",
        component: () => import("../views/send_notification/notification.vue"),
    },
    {
        path: base + "send_notification",
        name: "notification-history",
        component: () => import("../views/send_notification/history.vue"),
    },
];

const router = new Router({
    mode: "history",
    // base,
    routes: [
        ...vendorRoutes,
        ...restaurantRoutes,
        // ...resturantRoutes
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
router.beforeEach((to, from, next) => {
    $("body").addClass("sidebar-disable").removeClass("sidebar-enable");
    var noauth = [
        "login",
        "admin-login",
        "forgot-password",
        "vendor.login",
        "signup",
        "vendor.signup",
        "change-log",
        "driver-signup",
        "quick-signup",
        "permission-page",
        "subscriptions.details",
        "account-setup",
    ];
    let nosideMenuRoute = [
        "vendor.vendor.add.customization",
        "vendor-add-sub-customization",
    ];
    const menu = vendorRoutes.find((u) => u.name == to.name);
    // const sidemenu = urls.find(u => menu && menu.path.includes(u.link));
    const sidemenu = urls.find(
        (u) =>
            menu &&
            ((menu.path.includes(u.link) && menu.path.startsWith(u.link)) ||
                menu.link == u.link)
    );

    if (!noauth.includes(to.name)) {
        if (!Vue.prototype.$auth || !Vue.prototype.$auth.setting) {
            Vue.prototype.$auth.setUser((type) => {
                if (noauth.includes(type)) next({ name: type });
                else {
                    if (
                        Vue.prototype.$auth.user.login_type != "vendor" ||
                        menu.access ||
                        Vue.prototype.$auth.user.access.some((s) =>
                            sidemenu.permissions.some((p) => p.includes(s))
                        )
                    ) {
                        if (
                            to.name == "call-center-dashboard" &&
                            !Vue.prototype.$auth.isCallcenterActive()
                        ) {
                            error_message("Permission required");
                            if (from.name) {
                                next({ name: from.name });
                            } else {
                                next({ name: "login" });
                            }
                        } else if (
                            to.name == "web-layout-setting" &&
                            !Vue.prototype.$auth.isWebOrderingActive()
                        ) {
                            error_message("Permission required");
                            if (from.name) {
                                next({ name: from.name });
                            } else {
                                next({ name: "login" });
                            }
                        } else if (
                            to.name == "send-notification" &&
                            !Vue.prototype.$auth.isNotificationActive()
                        ) {
                            error_message("Permission required");
                            if (from.name) {
                                next({ name: from.name });
                            } else {
                                next({ name: "login" });
                            }
                        } else if (
                            to.name == "chat-list" &&
                            !Vue.prototype.$auth.isChatActive()
                        ) {
                            error_message("Permission required");
                            if (from.name) {
                                next({ name: from.name });
                            } else {
                                next({ name: "login" });
                            }
                        } else {
                            //next();
                            let host = window.location.hostname;
                            if (host.includes("pos")) {
                                let routename = "table-view";
                                next({ name: routename });
                            } else {
                                next();
                            }
                        }
                    } else {
                        error_message("Permission required");
                        if (from.name) {
                            next({ name: from.name });
                        } else {
                            next({ name: "permission-page" });
                        }
                    }
                }
            });
        } else {
            let menuAccess = menu
                ? menu.access
                : nosideMenuRoute.includes(to.name)
                ? true
                : undefined;
            if (
                Vue.prototype.$auth.user.login_type != "vendor" ||
                menuAccess ||
                (sidemenu &&
                    Vue.prototype.$auth.user.access.some((s) =>
                        sidemenu.permissions.some((p) => p.includes(s))
                    ))
            ) {
                next();
            } else {
                error_message("Permission required");
                // next({name: from.name});
                if (from.name) {
                    next({ name: from.name });
                } else {
                    next({ name: "login" });
                }
            }
        }
    } else {
        // if (from.name == 'signup' || from.name == 'quick-signup') {
        //   next({name: from.name});
        // } else {
        next();
        // }
    }
});
export default router;
