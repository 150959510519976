import config from "../config";

export default {
    config: config,
};

const base = config.base_folder;

export const clientmenuItems = [
    {
        id: 1,
        label: "Dashboard",
        icon: "bx-home-circle",
        link: base + "dashboard",
    },
    {
        id: 2,
        label: "Clients",
        icon: "bx-user",
        link: base + "client",
    },
    {
        id: 5,
        label: "Reseller",
        icon: "bx bx-store",
        link: base + "reseller",
    },
    {
        id: 3,
        label: "Currency",
        icon: "bx-dollar",
        link: base + "currency",
    },
    {
        id: 4,
        label: "Business Type",
        icon: "bx bxs-business",
        link: base + "business-type",
    },
    {
        id: 6,
        label: "Client Reports",
        icon: "bx bx-file",
        link: base + "client-reports",
    },
    // {
    //     id: 7,
    //     label: "Release Notes",
    //     icon: "bx bx-note",
    //     link: base + "dashboard-release-notes",
    // },
    {
        id: 8,
        label: "Maintenance Text",
        icon: "bx bx-cog",
        link: base + "maintenance/system",
    },
    {
        id: 9,
        label: "Reported Bugs",
        icon: "bx bxs-file",
        link: base + "reported-bugs",
    },
];
